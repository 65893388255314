import axios from 'axios';
import { SendEmailUrl } from "../Config/apiconfig";
import { cryptAPIKey, encryptUsingAES256Base64 } from "./Security/Crypto";

export const SendEmail = async (To, From, Subject, Body) => {
  try {
    var data = {
      'to': To,
      'from': From,
      'subject': Subject,
      'html': Body
    }

    const requestHeaders = {
      'Content-Type': 'application/json; charset=utf-8',
      'X-ApiKey': cryptAPIKey(process.env.REACT_APP_SECRET_KEY)
    };

    const encryptedData = encryptUsingAES256Base64(JSON.stringify(data));
    const response = await axios.post(SendEmailUrl, encryptedData, { headers: requestHeaders, timeout: 30000 });
    return { data: response.data, error: null };
  } catch (error) {
    return { data: null, error: `An error occurred: ${error.message}` };
  }
}