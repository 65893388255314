import React from 'react';

function Loading() {

  return (
    <div className='loading-div'>
      <div className='loading-gif'>
        <img src='src/image/loading.gif' alt='loading gif' />
      </div>
    </div>
  )
}

export default Loading