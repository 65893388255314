import { configureStore } from '@reduxjs/toolkit';
import SiteWideSettingsReducer from './SiteWideSettings';

const store = configureStore({
	reducer: {
		SiteWideSettings: SiteWideSettingsReducer
	},
	devTools: process.env.REACT_APP_ENV_TYPE !== 'prod',
});

export default store;