import React from "react";

function ContactSupport() {

  return (
    <div>
      Contact Support
    </div>
  )
}

export default ContactSupport;