import React, { useRef, useState } from 'react';
import UsePageTitle from '../Components/usePageTitle';
import { SendEmail } from '../Components/SendEmail';

import '../Style/ContactUs.css';

function ContactUs() {
  UsePageTitle('Contact Us - Waoo Network');
  const form = useRef();
  const [statusMessage, setStatusMessage] = useState('');

  const sendEmail = async (e) => {
    e.preventDefault();
    setStatusMessage('Sending...');

    const formData = new FormData(form.current);
    const formProps = Object.fromEntries(formData);
    const to = 'contactus@waoonetwork.com'
    const from = 'noreply@waoonetwork.com';
    const subject = 'WaooNetwork - Contact Us';
    const Body = `Name: ${formProps.user_name}\nEmail: ${formProps.user_email}\nMessage: ${formProps.message}`;

    const response = await SendEmail(to, from, subject, Body);
    console.log('response', response?.data);

    if (response?.data?.toLowerCase().includes('email sent successfully'.toLowerCase())) {
      setStatusMessage('Email successfully sent!');
    } else {
      setStatusMessage('Failed to send email. Please try again later.');
    }
  };

  return (
    <div className="contact-container">
      <h1>Contact Us</h1>
      <p>Have a question or need more information? Reach out to us using the form below:</p>

      <form ref={form} onSubmit={sendEmail}>
        <div className="form-group">
          <label htmlFor="name">Name:</label>
          <input type="text" className="form-control" id="name" name="user_name" required />
        </div>

        <div className="form-group">
          <label htmlFor="email">Email:</label>
          <input type="email" className="form-control" id="email" name="user_email" required />
        </div>

        <div className="form-group">
          <label htmlFor="message">Message:</label>
          <textarea className="form-control" id="message" name="message" rows="4" required></textarea>
        </div>

        <button type="submit" className="submit-button mb-3">Submit</button>
      </form>

      {statusMessage && <p className="status-message">{statusMessage}</p>}

      <p>You can also contact us via email at contactus@waoonetwork.com</p>
    </div>
  );
}

export default ContactUs;
