import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const Create = ({ show, onHide, onCreate, StatusTypes, Customers, CustomerUsers }) => {
  const initialFormData = {
    InvoiceNum: "",
    InvoiceDate: new Date(),
    CustomerId: "",
    Title: "",
    Description: "",
    StatusId: "",
    StartDate: "",
    EndDate: ""
  };

  const [formData, setFormData] = useState(initialFormData);
  const [FilteredUsers, setFilteredUsers] = useState([]);
  const [errors, setErrors] = useState({});

  const handleChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value
    });
  }

  const handleDateChange = (variable, date) => {
    handleChange(variable, date);
  }

  const handleCustomerChange = (CustomerId) => {
    handleChange('CustomerId', CustomerId);
  }

  const handleCreate = () => {
    const newErrors = {};
    if (!formData.InvoiceNum) newErrors.InvoiceNum = "Invoice Number is required.";
    if (!formData.InvoiceDate) newErrors.InvoiceDate = "Invoice Date is required.";
    if (!formData.CustomerId) newErrors.CustomerId = "Customer is required.";
    if (!formData.StatusId) newErrors.StatusId = "Status is required.";

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    const date = formData.InvoiceDate;
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;
    const newData = { ...formData, InvoiceDate: formattedDate };

    onCreate(newData);
    setFormData(initialFormData);
  }

  const handleModalHide = () => {
    onHide();
    setFormData(initialFormData);
    setErrors({});
    handleCustomerChange('');
  };

  return (
    <Modal show={show} onHide={handleModalHide}>
      <Modal.Header closeButton>
        <Modal.Title>Create Invoice</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="InvoiceDate">
            <Form.Label>Invoice Date</Form.Label>
            <br />
            <DatePicker
              selected={formData.InvoiceDate}
              onChange={date => handleDateChange('InvoiceDate', date)}
              dateFormat="yyyy-MM-dd"
              className="form-control"
            />
            {errors.InvoiceDate && <div className="text-danger">{errors.InvoiceDate}</div>}
          </Form.Group>
          <Form.Group controlId="InvoiceDate">
            <Form.Label>Date Range</Form.Label>
            <br />
            <DatePicker
              selected={formData.StartDate}
              onChange={date => handleDateChange('StartDate', date)}
              dateFormat="yyyy-MM-dd"
              className="form-control"
            />
            {errors.StartDate && <div className="text-danger">{errors.StartDate}</div>}
            <DatePicker
              selected={formData.EndDate}
              onChange={date => handleDateChange('EndDate', date)}
              dateFormat="yyyy-MM-dd"
              className="form-control"
            />
            {errors.EndDate && <div className="text-danger">{errors.EndDate}</div>}
          </Form.Group>
          <Form.Group controlId="InvoiceNum">
            <Form.Label>Invoice Number</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter invoice number"
              value={formData.InvoiceNum}
              onChange={(e) => handleChange("InvoiceNum", e.target.value)}
            />
            {errors.InvoiceNum && <div className="text-danger">{errors.InvoiceNum}</div>}
          </Form.Group>
          <Form.Group controlId="CustomerId">
            <Form.Label>Customer</Form.Label>
            <Form.Control
              as="select"
              value={formData.CustomerId}
              onChange={(e) => handleCustomerChange(e.target.value)}
            >
              <option value="">Select Customer</option>
              {Customers.map((Customer) => (
                <option key={Customer.CustomerId} value={Customer.CustomerId}>{Customer.CustomerName}</option>
              ))}
            </Form.Control>
            {errors.CustomerId && <div className="text-danger">{errors.CustomerId}</div>}
          </Form.Group>
          <Form.Group controlId="Title">
            <Form.Label>Title</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter title"
              value={formData.Title}
              onChange={(e) => handleChange("Title", e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="Description">
            <Form.Label>Description</Form.Label>
            <Form.Control
              as="textarea"
              rows={5}
              placeholder="Enter description"
              value={formData.Description}
              onChange={(e) => handleChange("Description", e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="StatusId">
            <Form.Label>Status</Form.Label>
            <Form.Control
              as="select"
              value={formData.StatusId}
              onChange={(e) => handleChange("StatusId", e.target.value)}
            >
              <option value="">Select Status</option>
              {StatusTypes.map((Status) => (
                <option key={Status.ID} value={Status.ID}>{Status.Name}</option>
              ))}
            </Form.Control>
            {errors.StatusId && <div className="text-danger">{errors.StatusId}</div>}
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>Cancel</Button>
        <Button variant="primary" onClick={handleCreate}>Create</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default Create;
