import React from 'react';
import packageJson from '../../../package.json';

import '../../Style/Footer.css';

function Footer() {

  return (
    <footer>
      Waoo Network Version {packageJson.version}
    </footer>
  );
}

export default Footer;