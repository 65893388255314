import React, { Suspense } from "react";
import Loading from '../Components/Loading';
import Header from '../Components/Layout/Header';
import Body from '../Components/Layout/Body';
import Footer from '../Components/Layout/Footer';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import '@fortawesome/fontawesome-free/css/all.min.css';

function App() {

  return (
<div style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
      <Suspense fallback={<Loading />}>
      <Header />
      <div style={{ flex: 1 }}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <Body />
            </div>
          </div>
        </div>
      </div>
      <Footer />
      </Suspense>
    </div>
  );
};

export default App;
