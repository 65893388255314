import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ApiCall } from "../../Components/ApiCall";
import Button from 'react-bootstrap/Button';
import Create from "../../Components/WorkOrder/Create";
import { useSelector } from "react-redux";

function WorkOrders() {
  const navigate = useNavigate();
  const UserAccount = useSelector((state) => state?.SiteWideSettings?.UserAccount);
  const [StatusTypes, setStatusTypes] = useState([]);
  const [BillingRateTypes, setBillingRateTypes] = useState([]);
  const [CommunicationTypes, setCommunicationTypes] = useState([]);
  const [Customers, setCustomers] = useState([]);
  const [CustomerUsers, setCustomerUsers] = useState([]);
  const [workOrders, setWorkOrders] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const fetchWorkOrders = async () => {
    var data = {};
    const response = await ApiCall('usp_WorkOrderHistory', data);
    setStatusTypes(response?.data?.Table || []);
    setBillingRateTypes(response?.data?.Table1 || []);
    setCommunicationTypes(response?.data?.Table2 || []);
    setCustomers(response?.data?.Table3 || []);
    setCustomerUsers(response?.data?.Table4 || []);
    setWorkOrders(response?.data?.Table5 || []);
  }

  useEffect(() => {
    fetchWorkOrders();
  }, [])

  const handleCreateWorkOrder = async (data) => {
    const convertedData = {};
    for (const key in data) {
      if (Object.hasOwnProperty.call(data, key)) {
        convertedData[key] = String(data[key]);
      }
    }

    data['RequestType'] = 'Insert';
    await ApiCall('usp_WorkOrder_CRUD', data)
    setShowModal(false);
    fetchWorkOrders();
  }

  return (
    <div className="container mt-4">
      {UserAccount?.UserType === 'Admin' && (
        <Button variant="primary" onClick={() => setShowModal(true)}>Create Work Order</Button>
      )}
      <table className="table">
        <thead>
          <tr>
            <th>Num</th>
            <th>Date</th>
            <th>Customer</th>
            <th>Description</th>
            <th>Duration</th>
            <th>Status</th>
            <th>Type</th>
            <th>Users</th>
            <th>Invoice Num</th>
          </tr>
        </thead>
        <tbody>
          {workOrders.map((workOrder, index) => (
            <tr key={index}>
              <td>{workOrder.ServiceNum}</td>
              <td className='text-nowrap'>{workOrder.ServiceDate}</td>
              <td className='text-nowrap'>{workOrder.CustomerName}</td>
              <td>{workOrder.Description}</td>
              <td>{workOrder.Duration}</td>
              <td>{workOrder.Status}</td>
              <td>{workOrder.CommType}</td>
              <td className='text-nowrap'>
                {workOrder.Users.split(',').map((user, index) => (
                  <div key={index}>{user.trim()}</div>
                ))}
              </td>
              <td>{workOrder.InvoiceNum}</td>
            </tr>
          ))}
        </tbody>
      </table>

      <Create
        show={showModal}
        onHide={() => setShowModal(false)}
        onCreate={handleCreateWorkOrder}
        StatusTypes={StatusTypes}
        BillingRateTypes={BillingRateTypes}
        CommunicationTypes={CommunicationTypes}
        Customers={Customers}
        CustomerUsers={CustomerUsers}
      />
    </div>
  )
}

export default WorkOrders;
