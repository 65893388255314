import React from "react";

function KnowledgeBase() {

  return (
    <div>
      KnowledgeBase
    </div>
  )
}

export default KnowledgeBase;