
function Profile() {

  return (
    <div>
      Profile
    </div>
  )
}

export default Profile;