import React, { useEffect, useState } from "react";
import { ApiCall } from "../../Components/ApiCall";
import { useNavigate } from "react-router-dom";

function UserAccounts() {
  const navigate = useNavigate();
  const [userAccounts, setUserAccounts] = useState([]);

  useEffect(() => {
    const fetchUsers = async () => {
      var data = {};
      const response = await ApiCall('usp_UserAccounts', data);
      setUserAccounts(response?.data?.Table || []);
    };

    fetchUsers();
  }, []);

  const handleEditClick = (userID) => {
    navigate(`/UserAccount/${userID}`);
  };

  return (
    <div className="container mt-4">
      <h2>User Accounts</h2>
      <table className="table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Active</th>
            <th>Customer</th>
            <th>User Type</th>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Email</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {userAccounts.map((userAccount, index) => (
            <tr key={index}>
              <td>{userAccount.ID}</td>
              <td><input type="checkbox" checked={userAccount.Active} disabled /></td>
              <td>{userAccount.CustomerName}</td>
              <td>{userAccount.UserType}</td>
              <td>{userAccount.FirstName}</td>
              <td>{userAccount.LastName}</td>
              <td>{userAccount.Email}</td>
              <td>
                <button
                  className="btn btn-sm btn-primary"
                  onClick={() => handleEditClick(userAccount.ID)}
                >
                  Edit
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default UserAccounts;
