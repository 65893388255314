import SiteRoutes from "../Route/SiteRoutes.js";

import '../../Style/Body.css';

function Body() {

  return (
    <div className="body">
      <SiteRoutes />
    </div>
  )
}

export default Body;