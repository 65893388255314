import React, { useState } from 'react';

import '../../Style/Calendar.css';

const Calendar = () => {
  const [date, setDate] = useState(new Date());
  const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  // sample event data
  const eventData = [
    { date: '2024-04-04', time: '09:00', title: 'Meeting with John' },
    { date: '2024-04-05', time: '13:30', title: 'Lunch with Sarah' },
    { date: '2024-04-08', time: '10:00', title: 'Interview with Jane' },
    { date: '2024-04-10', time: '14:00', title: 'Team building event' },
  ];

  const getDaysInMonth = (year, month) => {
    return new Date(year, month + 1, 0).getDate();
  }

  const getFirstDayOfMonth = (year, month) => {
    return new Date(year, month, 1).getDay();
  }

  const handlePrevMonth = () => {
    setDate(new Date(date.getFullYear(), date.getMonth() - 1, 1));
  }

  const handleNextMonth = () => {
    setDate(new Date(date.getFullYear(), date.getMonth() + 1, 1));
  }

  const renderDays = () => {
    const daysInMonth = getDaysInMonth(date.getFullYear(), date.getMonth());
    const firstDayOfMonth = getFirstDayOfMonth(date.getFullYear(), date.getMonth());
    const rows = [];
    let daysCounter = 1;
    let isPrevMonth = false;
    for (let i = 0; i < 6; i++) {
      const cells = [];
      for (let j = 0; j < 7; j++) {
        if (i === 0 && j < firstDayOfMonth) {
          cells.push(<td key={`prev_${j}`} className="calendar-prev-month">{getDaysInMonth(date.getFullYear(), date.getMonth() - 1) - firstDayOfMonth + j + 1}</td>);
          isPrevMonth = true;
        } else if (daysCounter > daysInMonth) {
          cells.push(<td key={`next_${j}`} className="calendar-next-month">{daysCounter - daysInMonth}</td>);
          daysCounter++;
        } else {
          const classNames = ['calendar-current-month'];
          if (isPrevMonth) {
            classNames.push('calendar-prev-month');
            isPrevMonth = false;
          } else if (j === 0 || j === 6) {
            classNames.push('calendar-weekend');
          }

          // check if there are any events on this day
          const eventsOnDay = eventData.filter((event) => {
            const eventDate = new Date(event.date);
            return eventDate.getFullYear() === date.getFullYear() && eventDate.getMonth() === date.getMonth() && eventDate.getDate() === daysCounter;
          });

          // render the day cell with events
          cells.push(
            <td key={`current_${j}`} className={classNames.join(' ')}>
              <div className="calendar-day-number">{daysCounter}</div>
              <div className="calendar-events">
                {eventsOnDay.map((event, index) => (
                  <div key={index} className="calendar-event">
                    <div className="calendar-time">{event.time}</div>
                    <div className="calendar-title">{event.title}</div>
                  </div>
                ))}
              </div>
            </td>
          );

          daysCounter++;
        }
      }
      rows.push(<tr key={i}>{cells}</tr>);
      if (daysCounter > daysInMonth) {
        break;
      }
    }
    return rows;
  }

  return (
    <div className='calendar-container'>
      <a href='https://calendar.app.google/ZUBRg6skSTv9oqPX7'>View Google Calendar</a>
      <div className='calendar-header'>
        <button onClick={handlePrevMonth}>&lt;</button>
        <span>{months[date.getMonth()]}, {date.getFullYear()}</span>
        <button onClick={handleNextMonth}>&gt;</button>
      </div>
      <table className='calendar'>
        <thead>
          <tr>{days.map((day) => <th key={day}>{day}</th>)}</tr>
        </thead>
        <tbody>
          {renderDays()}
        </tbody>
      </table>
    </div>
  );
}

export default Calendar;

