import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import UsePageTitle from '../Components/usePageTitle';
import { ApiCall } from '../Components/ApiCall';

import '../Style/Home.css';

function Home() {
  const dispatch = useDispatch();
  UsePageTitle('Waoo Network - Development Consulting');

  useEffect(() => {
    const fetchUser = async () => {
      var data = {
        // 'EditUser': 0,
        // 'ID': ID
      }

      const response = await ApiCall('usp_test', data);
    };

    fetchUser();
  }, [dispatch])

  return (
    <div className="home-container">
      <div className="home-header">
        <div className="header-content">
          <h1>Welcome to Waoo Network</h1>
          <p>
            Specializing in creating custom solutions tailored for your unique business needs.
          </p>
        </div>
      </div>

      <section className="services-offered">
        <h1>Services Offered</h1>
        <div className="services-list">
          <div className="service">
            <h3>Custom Software Development</h3>
            <p>Build innovative custom software tailored to your business.</p>
          </div>
          <div className="service">
            <h3>IT Consulting</h3>
            <p>Expert advice to navigate the ever-evolving tech landscape.</p>
          </div>
        </div>
      </section>

      <section className="why-choose-us">
        <h2>Why Choose Waoo Network</h2>
        <ul>
          <li>Industry Expertise</li>
          <li>Client-Centric Approach</li>
          <li>Innovation at the Core</li>
        </ul>
      </section>

      <section className="get-in-touch">
        <h2>Get in Touch</h2>
        <p>
          Ready to elevate your business? Contact us to schedule a consultation.
        </p>
        <Link to="/contactus" className="contact-button">Contact Us</Link>
      </section>
    </div>
  );
}

export default Home;