import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { ApiCall } from "../../Components/ApiCall";

function PasswordResetConfirm() {
  let { ResetRowGuid } = useParams();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [results, setResults] = useState('');
  const [resultsMessage, setResultsMessage] = useState('');
  const [invalidGuid, setInvalidGuid] = useState(false);

  const isValidGUID = (guid) => {
    const guidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
    return guidRegex.test(guid);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (isValidGUID(ResetRowGuid)) {
          var data = {
            'ResetRowGuid': ResetRowGuid
          }
          const response = await ApiCall('usp_ResetPasswordConfirm', data)
          setResults(response?.data?.Table[0]["ResultsMessage"]);
          setInvalidGuid(false);
          setResultsMessage(resultsMessage);
        } else {
          setResults('Failed');
          setInvalidGuid(true);
          setResultsMessage('');
        }
      } catch (error) {
        setResults('Failed');
        setInvalidGuid(true);
        setResultsMessage('');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [ResetRowGuid, dispatch]);

  return (
    <div className="container mt-5">
      <div className="row justify-content-center">
        <div className="col-md-6">
          <div className="card">
            <div className="card-body">
              {loading ? (
                <div>Loading...</div>
              ) : (
                <div>
                  {results === 'Successful' && (
                    <div>
                      <p className="text-success">You have successfully reset your password!</p>
                      <a href='../Login' className="btn btn-primary">Click here to login</a>
                    </div>
                  )}
                  {resultsMessage !== '' && (
                    <p className="text-danger" >
                      {resultsMessage}
                    </p>
                  )}
                  {invalidGuid === true && (
                    <p className="text-danger" >
                      This password reset was not successful. Please <a href="../ResetPassword">click here</a> to retry resetting your password.
                    </p>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PasswordResetConfirm;