import React from "react";
import UsePageTitle from '../../Components/usePageTitle';

function Error404() {
UsePageTitle('Error 404')
  return (
    <div>
      Error 404
    </div>
  );
}

export default Error404;