import { useEffect, useState } from "react";
import { ApiCall } from "../../Components/ApiCall";

function Customers() {
  const [CustomerAccounts, setCustomerAccounts] = useState([]);

  useEffect(() => {
    const fetchCustomerAccounts = async () => {
      var data = {};
      const response = await ApiCall('usp_CustomerAccounts_CRUD', data);
      setCustomerAccounts(response?.data?.Table || []);
    };

    fetchCustomerAccounts();
  }, []);

  return (
    <div className="container mt-4">
      <h2>Customers</h2>
      <table className="table">
        <thead>
          <tr>
            <th>Id</th>
            <th>Customer Name</th>
            <th>Active</th>
            <th>Last Invoice</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {CustomerAccounts.map((CustomerAccount, index) => (
            <tr key={index}>
              <td>{CustomerAccount.CustomerId}</td>
              <td>{CustomerAccount.CustomerName}</td>
              <td>{CustomerAccount.Active}</td>
              <td></td>
              <td>Button to view</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default Customers;