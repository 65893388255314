let apiURL;
let SharedControllerUrl;

//localhost Testing
// apiURL = 'https://localhost:44348/api/';

//Production
apiURL = 'https://api.waoonetwork.com/api/';

SharedControllerUrl = apiURL + 'Shared/'

export const ApiCallUrl = SharedControllerUrl + 'ApiCall';
export const SendEmailUrl = SharedControllerUrl + 'SendEmail'