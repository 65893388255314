import React from "react";

function DataRecovery() {

  return (
    <div>
      Data Recovery
    </div>
  )
}

export default DataRecovery;