import { useEffect, useState } from "react";
import { ApiCall } from "../../Components/ApiCall";

import '../../Style/Contracts.css';

function Settings() {
  const [Contracts, setContracts] = useState([]);

  useEffect(() => {
    const fetchContracts = async () => {
      var data = {};
      const response = await ApiCall('usp_Contracts_CRUD', data);
      setContracts(response?.data?.Table || []);
    };

    fetchContracts();
  }, []);

  const handleEditClick = (ContractNum) => {

  };

  return (
    <div className="container mt-4">
      <h2>Contracts</h2>
      <table className="table">
        <thead>
          <tr>
            <th>Customer</th>
            <th>Contract Num</th>
            <th>Title</th>
            <th>Description</th>
            <th>Status</th>
            <th>Start</th>
            <th>End</th>
            <th>Cycle</th>
            <th>Rate</th>
            {/* <th>Actions</th> */}
          </tr>
        </thead>
        <tbody>
          {Contracts.map((Contract, index) => (
            <tr key={index}>
              <td>{Contract.CustomerName}</td>
              <td>{Contract.ContractNum + '.' + Contract.LineNumber}</td>
              <td>{Contract.Title}</td>
              <td>{Contract.Description}</td>
              <td>{Contract.Status}</td>
              <td>{Contract.StartDate}</td>
              <td>{Contract.EndDate}</td>
              <td>{Contract.BillingCycleQty + ' ' + Contract.BillingRateName + ', ' + Contract.BillingCycleName}</td>
              <td>{Contract.BillingRate + ' Per ' + Contract.BillingRateName}</td>
              {/* <td>
                <button
                  className="btn btn-sm btn-primary"
                  onClick={() => handleEditClick(contract.ContractNum)}
                >
                  Edit
                </button>
              </td> */}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default Settings;