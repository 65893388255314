import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ApiCall } from '../../Components/ApiCall';
import { ProcessLogin } from '../../Redux/SiteWideSettings';

function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const UserAccount = useSelector((state) => state?.SiteWideSettings?.UserAccount);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [results, setResults] = useState('');
  const [resultsMessage, setResultsMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const handleLogin = async (e) => {
    e.preventDefault();
    document.getElementById('email').style.border = '1px solid #ced4da';
    document.getElementById('password').style.border = '1px solid #ced4da';

    if (email.trim() === '' || !validateEmail(email) || password.trim() === '') {
      setResults('Failed');
      setResultsMessage('Please check your input fields');

      if (email.trim() === '' || !validateEmail(email)) {
        document.getElementById('email').style.border = '1px solid red';
      }
      if (password.trim() === '') {
        document.getElementById('password').style.border = '1px solid red';
      }
    } else {
      setLoading(true);
      try {
        var data = {
          'Email': email,
          'Password': password
        }
        var response = await ApiCall('usp_LoginAuth', data);
        var results = response?.data?.Table[0]
        var userData = response?.data?.Table1[0]
        setResults(results["LoginResults"]);
        setResultsMessage(results["LoginMessage"]);
        if (results["LoginResults"] === 'Successful') {
          userData["IsLoggedIn"] = true;
          dispatch(ProcessLogin(userData));
          // if ((User?.MfaAuthenticator === true || User?.MfaSms === true || User?.MfaEmail === true) && User?.MfaLoggedIn === false) {
          //   navigate('/Login/Mfa');
          // }
        }
      } finally {
        setLoading(false);
      }
    }
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  useEffect(() => {
    if (UserAccount && UserAccount.IsLoggedIn === true) {
      navigate('/');
    }
  }, [UserAccount, navigate]);

  return (
    <div className="container mt-5">
      <div className="row justify-content-center align-items-center"> {/* Updated */}
        <div className='mb-5'>
          <h1>Login</h1>
        </div>
        <div className="col-md-6 bg-light p-4 rounded shadow">
          <form onSubmit={handleLogin}> {/* Form element wrapping input fields */}
            <div className="mb-3">
              <label htmlFor="email" className="form-label">Email:</label>
              <input type="email" className="form-control" id="email" placeholder="Enter your email" value={email} onChange={(e) => setEmail(e.target.value)} autoComplete="username" />
            </div>
            <div className="mb-3">
              <label htmlFor="password" className="form-label">Password:</label>
              <input type="password" className="form-control" id="password" placeholder="Enter your password" value={password} onChange={(e) => setPassword(e.target.value)} autoComplete="current-password" />
            </div>
            <div className='mb-3 d-flex justify-content-center'> {/* Updated */}
              <button type="submit" className="btn btn-primary">
                Login
                {loading ? <i className="fa fa-spinner fa-spin " style={{ "marginLeft": "5px" }} /> : ''}
              </button>
            </div>
          </form>
          {results === 'Failed' && (
            <div className='alert alert-danger mb-3' role='alert'>
              {resultsMessage}
            </div>
          )}
          <div className='mb-3 d-flex justify-content-center'> {/* Updated */}
            <a href="./ResetPassword">Forgot Password?</a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;