import React, { useEffect, useState } from 'react';
import { ApiCall } from '../../Components/ApiCall';
import Create from '../../Components/Invoice/Create';
import Button from 'react-bootstrap/Button';
import { useSelector } from 'react-redux';

function Invoices() {
  const UserAccount = useSelector((state) => state?.SiteWideSettings?.UserAccount);
  const [StatusTypes, setStatusTypes] = useState([]);
  const [Customers, setCustomers] = useState([]);
  const [Invoices, setInvoices] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const fetchInvoices = async () => {
    var data = {};
    const response = await ApiCall('usp_Invoice_CRUD', data);
    setInvoices(response?.data?.Table || []);
    setStatusTypes(response?.data?.Table1 || []);
    setCustomers(response?.data?.Table2 || []);
  }

  useEffect(() => {
    fetchInvoices();
  }, [])

  const handleCreateInvoice = async (data) => {
    const convertedData = {};
    for (const key in data) {
      if (Object.hasOwnProperty.call(data, key)) {
        convertedData[key] = String(data[key]);
      }
    }

    data['RequestType'] = 'InsertHeader';
    await ApiCall('usp_Invoice_CRUD', data)
    setShowModal(false);
    fetchInvoices();
  }

  return (
    <div className="container mt-4">
      {UserAccount?.UserType === 'Admin' && (
        <Button variant="primary" onClick={() => setShowModal(true)}>Create Invoice</Button>
      )}
      <table className="table">
        <thead>
          <tr>
            <th>Invoice Num</th>
            <th>Invoice Date</th>
            <th>Customer</th>
            <th>Title</th>
            <th>Status</th>
            <th>Start Date</th>
            <th>End Date</th>
          </tr>
        </thead>
        <tbody>
          {Invoices.map((Invoice, index) => (
            <tr key={index}>
              <td>{Invoice.InvoiceNum}</td>
              <td>{Invoice.InvoiceDate}</td>
              <td>{Invoice.CustomerName}</td>
              <td>{Invoice.Title}</td>
              <td>{Invoice.Status}</td>
              <td>{Invoice.StartDate}</td>
              <td>{Invoice.EndDate}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <Create
        show={showModal}
        onHide={() => setShowModal(false)}
        onCreate={handleCreateInvoice}
        StatusTypes={StatusTypes}
        Customers={Customers}
      />
    </div>
  );
};

export default Invoices;
