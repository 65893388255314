import React from "react";

function WebDevelopment() {

  return (
    <div>
      Web Development
    </div>
  )
}

export default WebDevelopment;