import { createSlice } from "@reduxjs/toolkit";
import { encryptUsingAES256Base64, decryptUsingAES256Base64 } from '../Components/Security/Crypto';

var UserCookie = "WNUser";

const getLocalArrayValue = (localStorageKey, arrayKey) => {
  const encryptedValue = localStorage.getItem(localStorageKey);
  if (!encryptedValue) {
    return {};
  }
  else {
    const decryptedValue = decryptUsingAES256Base64(encryptedValue);
    const storedArray = JSON.parse(decryptedValue) || {};

    return storedArray[arrayKey] || '';
  }
}

export const ProcessLogin = (UserAccount) => async (dispatch) => {
  dispatch(setUserAccount(UserAccount))
}

export const LogOut = () => async (dispatch) => {
  localStorage.removeItem(UserCookie);
  dispatch(setUserAccount({}));
}

export const SiteWideSettingsSlice = createSlice({
  name: 'SiteWideSettings',
  initialState: {
    UserAccount: {
      ID: getLocalArrayValue(UserCookie, "ID"),
      Active: getLocalArrayValue(UserCookie, "Active"),
      UserType: getLocalArrayValue(UserCookie, "UserType"),
      IsLoggedIn: getLocalArrayValue(UserCookie, "IsLoggedIn"),
      AccountType: getLocalArrayValue(UserCookie, "AccountType"),
      FirstName: getLocalArrayValue(UserCookie, "FirstName"),
      LastName: getLocalArrayValue(UserCookie, "LastName"),
      Email: getLocalArrayValue(UserCookie, "Email"),
    },
    WebsiteVersion: {
      Cersion: '',
      Expirationxpiration: 0,
      AutoRefresh: false,
      AutoRefreshTries: 0,
      AutoRefreshDateTime: ''
    },
  },
  reducers: {
    setUserAccount: (state, action) => {
      state.UserAccount.ID = action.payload.ID;
      state.UserAccount.Active = action.payload.Active || false;
      state.UserAccount.UserType = action.payload.UserType;
      state.UserAccount.IsLoggedIn = action.payload.IsLoggedIn || false;
      state.UserAccount.AccountType = action.payload.AccountType;
      state.UserAccount.FirstName = action.payload.FirstName;
      state.UserAccount.LastName = action.payload.LastName;
      state.UserAccount.Email = action.payload.Email;

      localStorage.setItem(UserCookie, encryptUsingAES256Base64(JSON.stringify(state.UserAccount)));
    },
    setWebsiteVersion: (state, action) => {
      state.websiteVersion.version = action.payload;
    },
    setWebsiteExpiration: (state, action) => {
      state.websiteVersion.expiration = action.payload;
    },
    setAutoRefresh: (state, action) => {
      state.websiteVersion.autoRefresh = action.payload;
      if (action.payload === true) {
        //1 minutes
        state.websiteVersion.autoRefreshDateTime = Date.now() + (1 * 30 * 1000);
      }
    },
    setAutoRefreshTries: (state, action) => {
      state.websiteVersion.autoRefreshTries = action.payload;
    },
    setAutoRefreshDateTime: (state, action) => {
      state.websiteVersion.autoRefreshDateTime = action.payload;
    },
  },
});

export const {
  setUserAccount,
  setWebsiteVersion,
  setWebsiteExpiration,
  setAutoRefresh,
  setAutoRefreshTries,
  setAutoRefreshDateTime
} = SiteWideSettingsSlice.actions;

export default SiteWideSettingsSlice.reducer;
