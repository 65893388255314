import NavBar from '../Nav/Navbar.js';

import '../../Style/Header.css';

function Header() {
  return (
    <header>
      <NavBar />
    </header>
  )
}

export default Header;