import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';

const Create = ({ show, onHide, onCreate, StatusTypes, BillingRateTypes, CommunicationTypes, Customers, CustomerUsers }) => {
  const initialFormData = {
    ServiceDate: new Date(),
    CustomerId: "",
    Description: "",
    StatusId: "",
    CommunicationId: "",
    UserIds: "",
    BillingRate: "",
    BillingRateTypeId: "2"
  };

  const [formData, setFormData] = useState(initialFormData);
  const [FilteredUsers, setFilteredUsers] = useState([]);

  const handleChange = (name, value) => {
    if (name === "UserIds" && Array.isArray(value)) {
      value = value.join(",");
    }

    if (name === "BillingRate") {
      value = String(value);
    }

    setFormData({
      ...formData,
      [name]: value
    });
  }


  const handleDateChange = (date) => {
    handleChange('ServiceDate', date);
  }

  const handleCustomerChange = (CustomerId) => {
    handleChange('CustomerId', CustomerId);
    const filteredUsers = CustomerUsers.filter(user => String(user.CustomerId) === CustomerId);
    setFilteredUsers(filteredUsers);
  }

  const handleCreate = () => {
    const date = formData.ServiceDate;
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is zero-based
    const day = String(date.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;
    const newData = { ...formData, ServiceDate: formattedDate };

    onCreate(newData);
    setFormData({
      ServiceDate: new Date(),
      CustomerId: "",
      Description: "",
      StatusId: "",
      CommunicationId: "",
      UserIds: "",
      BillingRate: "",
      BillingRateTypeId: "2"
    });
  }

  const handleModalHide = () => {
    onHide();
    setFormData(initialFormData);
    handleCustomerChange('');
  };

  return (
    <Modal show={show} onHide={handleModalHide} >
      <Modal.Header closeButton>
        <Modal.Title>Create Work Order</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="ServiceDate">
            <Form.Label>Service Date</Form.Label>
            <br />
            <DatePicker
              selected={formData.ServiceDate}
              onChange={ServiceDate => handleDateChange(ServiceDate)}
              dateFormat="yyyy-MM-dd"
              className="form-control"
            />
          </Form.Group>
          <Form.Group controlId="CustomerId">
            <Form.Label>Customer</Form.Label>
            <Form.Control
              as="select"
              value={formData.CustomerId}
              onChange={(e) => handleCustomerChange(e.target.value)}
            >
              <option value="">Select Customer</option>
              {Customers.map((Customer) => (
                <option key={Customer.CustomerId} value={Customer.CustomerId}>{Customer.CustomerName}</option>
              ))}
            </Form.Control>
          </Form.Group>
          <Form.Group controlId="Description">
            <Form.Label>Description</Form.Label>
            <Form.Control
              as="textarea"
              rows={10}
              placeholder="Enter description"
              value={formData.Description}
              onChange={(e) => handleChange("Description", e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="BillingRate">
            <Form.Label>Billing Rate</Form.Label>
            <div className="d-flex align-items-center">
              <Form.Control
                type="number"
                step="0.01"
                placeholder="Enter billing rate"
                value={formData.BillingRate}
                onChange={(e) => handleChange("BillingRate", parseFloat(e.target.value))}
              />
              <Form.Control
                as="select"
                className="ml-2"
                value={formData.BillingRateTypeId}
                onChange={(e) => handleChange("BillingRateTypeId", e.target.value)}
              >
                <option value="">Select Rate</option>
                {BillingRateTypes.map((BillingRateType) => (
                  <option key={BillingRateType.BillingRateTypeId} value={BillingRateType.BillingRateTypeId}>{BillingRateType.Name}</option>
                ))}
              </Form.Control>
            </div>
          </Form.Group>
          <Form.Group controlId="StatusId">
            <Form.Label>Status</Form.Label>
            <Form.Control
              as="select"
              value={formData.StatusId}
              onChange={(e) => handleChange("StatusId", e.target.value)}
            >
              <option value="">Select Status</option>
              {StatusTypes.map((Status) => (
                <option key={Status.ID} value={Status.ID}>{Status.Name}</option>
              ))}
            </Form.Control>
          </Form.Group>
          <Form.Group controlId="CommunicationId">
            <Form.Label>Type</Form.Label>
            <Form.Control
              as="select"
              value={formData.CommunicationId}
              onChange={(e) => handleChange("CommunicationId", e.target.value)}
            >
              <option value="">Select Type</option>
              {CommunicationTypes.map((type) => (
                <option key={type.ID} value={type.ID}>{type.Name}</option>
              ))}
            </Form.Control>
          </Form.Group>
          <Form.Group controlId="UserIds">
            <Form.Label>Users</Form.Label>
            <Form.Control
              as="select"
              multiple
              disabled={!formData.CustomerId}
              value={formData.UserIds || []}
              onChange={(e) => handleChange("UserIds", Array.from(e.target.selectedOptions, option => option.value))}
            >
              {FilteredUsers.map((User) => (
                <option key={User.ID} value={User.ID}>{User.FirstName + ' ' + User.LastName}</option>
              ))}
            </Form.Control>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>Cancel</Button>
        <Button variant="primary" onClick={handleCreate}>Create</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default Create;