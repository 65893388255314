import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { ApiCall } from '../../Components/ApiCall';

function ResetPassword() {
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [resetRequested, setResetRequested] = useState(false);
  const [results, setResults] = useState('');
  const [resultsMessage, setResultsMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const handleResetPassword = async () => {
    document.getElementById('email').style.border = '1px solid #ced4da';
    document.getElementById('newpassword').style.border = '1px solid #ced4da';
    document.getElementById('confirmNewPassword').style.border = '1px solid #ced4da';

    if (email.trim() === '' || !validateEmail(email) || newPassword.trim() === '' || newPassword !== confirmNewPassword) {
      setResults('Failed');
      setResultsMessage('Please check your input fields');

      if (email.trim() === '' || !validateEmail(email)) {
        document.getElementById('email').style.border = '1px solid red';
      }
      if (newPassword.trim() === '') {
        document.getElementById('newpassword').style.border = '1px solid red';
      }
      if (newPassword !== confirmNewPassword) {
        document.getElementById('confirmNewPassword').style.border = '1px solid red';
      }
    } else {
      setLoading(true);
      setResetRequested(true);
      var data = {
        'Email': email,
        'NewPassword': newPassword
      }
      const response = await ApiCall('usp_ResetPassword', data);

      setResults(response?.data?.Table[0]["ResetPasswordResults"]);
      setResultsMessage(response?.data?.Table[0]["ResetPasswordMessage"]);
      setLoading(false);
    }
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  return (
    <div className="container mt-5">
      <div className="row justify-content-center">
        <div className="col-md-6 bg-light p-4 rounded shadow">
          <h2>Reset Password</h2>
          <form>
            <div className="mb-3">
              <label htmlFor="email" className="form-label">Email:</label>
              <input type="email" disabled={resetRequested} className="form-control" id="email" placeholder="Enter your email" value={email} onChange={(e) => setEmail(e.target.value)} autoComplete="username" />
            </div>
            <div className="mb-3">
              <label htmlFor="newpassword" className="form-label">New Password:</label>
              <input type="password" disabled={resetRequested} className="form-control" id="newpassword" placeholder="Enter your new password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} autoComplete="new-password" />
            </div>
            <div className="mb-3">
              <label htmlFor="confirmNewPassword" className="form-label">Confirm New Password:</label>
              <input type="password" disabled={resetRequested} className="form-control" id="confirmNewPassword" placeholder="Confirm your new password" value={confirmNewPassword} onChange={(e) => setConfirmNewPassword(e.target.value)} autoComplete="new-password" />
            </div>
            <button type="button" className="btn btn-primary" onClick={handleResetPassword}>
              Reset Password
              {loading ? <i className="fa fa-spinner fa-spin " style={{ "marginLeft": "5px" }} /> : ''}
            </button>
            {results === 'Successful' && resultsMessage === '' && (
              <div style={{ color: 'green', marginTop: '10px' }}>
                {results} - Check your email for a confirmation email
              </div>
            )}
            {(results !== 'Successful' || resultsMessage !== '') && (
              <div style={{ color: 'red', marginTop: '10px' }}>
                {results}
                {resultsMessage !== '' && (- { resultsMessage })}
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;